.modal__confirmOrder
    min-width: 70%
    .modal__confirmOrderBody
        padding: 1rem !important
        h6
            font-weight: bold
        span
            display: block
            padding: 0 0 1rem 0
        .current__orderTable
            width: 30%
            margin-bottom: 1.5rem
        .divider__line
            height: 1px
            width: 100%
            background-color: #ccc
            margin: 1.5rem 0
        .confirmOrder__table
            thead
                background-color: #f4f4f4
            tr>td
                border-bottom: 1px solid #f4f4f4
            tr > td:first-child
                border-left: 1px solid #f4f4f4
            tr > td:last-child
                border-right: 1px solid #f4f4f4
            tr:last-child
                background-color: #f4f4f4
            > :not(:first-child)
                border-top: 1px solid !important
        .po__details
            margin: 1.5rem 0
            width: 50%
            .po__details__form
                display: grid
                grid-template-columns: repeat(2,1fr)
                gap: 30px
                margin-bottom: 1.5rem

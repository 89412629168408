@import './../../../../styles/variables'
.menu__highlight
  visibility: hidden
  height: 95%
  width: 4px
  background-color: $cds-navbarIconHighlight
.sub__menu
  background-color: #262626
  a
    color: $cds-navbarText
    padding-left: 2rem
    transition: all 0.2s ease-out
  a:hover, a:focus, a:active, .active
    background: rgba($cds-navbarIconHighlight, 0.1)
    color: $cds-navbarIconHighlight
           
.menu-block
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  .logo-block
    margin: 0px 1.5rem
    padding: 20px 0
    a
      text-decoration: none
      color: $cds-primary
      .svg__icon
        transition: all 0.2s ease-out
        fill: $cds-primary
      &:hover .svg__icon
        transform: scale(1.05)
        transition: all 0.3s ease-out

    span
      display: block
      color: $cds-white
      font-size: 12px
      margin: 3px 0

  .btn.btn-request
    margin: 0.8rem 1.5rem 0 1.5rem
    padding: 0.5rem 3.5rem
    background-color: $cds-primary
    border-radius: 5px
    color: $cds-white
    font-size: 14px
    transition: all 0.2s ease-out
    &:hover
      transform: scale(1.05)
      transition: all 0.3s ease-out
  .menu-list
    margin-top: 20px
    display: flex
    flex-direction: column
    padding: 0

    &::-webkit-scrollbar
      width: 5px
    &::-webkit-scrollbar-thumb
      background: #888
      border-radius: 10px
      &:hover
        background: #555
        cursor: pointer
    &::-webkit-scrollbar-track
      background: #f1f1f1
    li
      display: block
      padding-block-end: 0.5rem

      .main__menu
        width: 100%
        display: flex
        text-decoration: none
        transition: all 0.2s ease-out

        &:hover
          transform: scale(1.01)
          transition: all 0.3s ease-out

        &:hover .menu__highlight
          visibility: visible

        .main__menuLeft
          width: 100%
          display: flex
          align-items: center

          span
            display: block
            color: $cds-navbarText
            padding: 10px 0 7px 10px
            border: 0
        .notification__badge
          color: $cds-white
          width: 2rem
          height: 1.3rem
          background: rgba($cds-white,0.2)
          border-radius: 1.8rem
          margin: auto
          display: flex
          justify-content: center
          align-items: center
          margin-right: 1.5rem
          font-size: 12px
        .submenu__arrow
          margin: auto
          margin-right: 1.5rem
          color: $cds-navbarText
        &:hover
          background: rgba($cds-navbarIconHighlight, 0.1)

          .svg__icon
            fill: $cds-navbarIconHighlight
          .main__menuLeft>span
            color: $cds-navbarIconHighlight
          .submenu__arrow
            color: $cds-navbarIconHighlight
          .notification__badge
            background: rgba($cds-primary,0.3)

      .active
        background: rgba($cds-navbarIconHighlight, 0.1)
        .svg__icon
          fill: $cds-navbarIconHighlight
        .main__menuLeft>span
          color: $cds-navbarIconHighlight
        .submenu__arrow
          color: $cds-navbarIconHighlight
        .menu__highlight
          visibility: visible

.bottom__menu
  display: flex
  width: 100%
  flex-direction: column
  .nav-link
    float: left !important
    align-items: center !important
    display: flex !important
    justify-content: center !important
    width: 100% !important
  .menu__line
    height: 0.05rem
    widhth: 100%
    background-color: #888
    margin: -0.5rem 1.5rem -0.1rem 1.5rem
  .bottom__menuContainer
    margin-top: 1rem
    height: 3rem
    margin-top: 1rem
    text-decoration: none
    .profile__menu
      width: 100%
      display: flex
      align-items: center
      text-decoration: none
      .profile__arrow
        color: $cds-navbarText
        margin: auto
        margin-right: 1.5rem
      .avatar
        width: 2rem
        height: 2rem      
      span        
        display: block
        color: $cds-white
        padding: 10px 0 7px 10px
        border: 0

        p
          font-size: 0.75rem
          margin: 0
          text-align: left
          color: $cds-navbarText
      &:hover, &:focus, &:active
        .menu__highlight
          visibility: hidden
          height: 55px
.profile__name
  height: 2rem
  width: 2rem
  background: rgba($cds-white,0.2)
  color: $cds-white
  border-radius: 1rem
  padding: 0.25rem
  font-size: 0.8rem
  display: flex
  justify-content: center
  align-items: center

.img__container
  margin-left: 1.25rem

@import './../../../styles/variables'

.modal__popup
    min-width: 450px !important
    width: 450px !important
    .modal-content
        min-height: 300px !important
        height: 300px !important
        .modal-header
            padding-left: 1rem !important
            padding-right: 1rem !important
        .modal-footer
            border-top: none !important
            padding-right: 1rem !important
    .popupModal__bodyContainer
        margin: 1.5rem 1rem 1rem 1rem !important
        display: flex
        flex-direction: column
        align-items: flex-start
        .body__titleContainer
            background-color: $cds-white
            position: sticky
            top: 0
            display: flex
            align-items: center
            .body__title
                padding-left: 1rem
        .body__contentContainer
            margin-left: 2.5rem
            margin-top: 0.5rem

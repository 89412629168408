@import '../../../../styles/variables'

.printSpecifications__form
    display: grid
    margin-bottom: 1rem
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 15px
    h6
        font-size: 1rem
        font-weight: bold
    .text-danger
        font-size: 1rem !important
        font-weight: lighter !important
        margin-top: 2px !important

.readOnlyForm__header
    display: flex
    margin-top: 1.5rem
    margin-bottom: 1.5rem
    justify-content: space-between
    align-items: center
    h6
        color: $cds-text
        margin-bottom: 0
.printSpecifications__fth
    font-size: 17px
    letter-spacing: 0.29px
    color: #1B1B1B
    line-height: 17px
    font-weight: 43px
.printSpecifications__ft
    font-size: 12px
    letter-spacing: 0.33px
    color: #545454
    line-height: 13px
.printSpecifications__fts
    font-size: 10px
    letter-spacing: 0.28px
    color: #545454
    line-height: 11px
.po-number-table
    > :not(:first-child)
        border-top: 1px solid currentColor !important
.text_box
    height: 70px
    width: 100px

.pricing__information
   .thead-light
        background-color: #ECECEC
        border: 1px solid #ECECEC
.place_supplier_order_marginContainer
    background-color: red !important
    margin: 10px 60px 10px 60px
.h6_style
    width: 200px !important
    color: #8D8C8D

.col_style
    display: flex

.quote_refrence_container
    display: flex
    padding: 10px
.quote_refrence_container div
    width: 250px !important
.underline
    display: flex
    width: 100%

    height: 1px
    background-color: black
.leaflet
    padding: 40px 0
.leaflet-inner
    padding: 20px
    border: 2px black
.leaflet h5
    background-color: #ECECEC
    padding: 10px 50px
    border: 2px black
    margin: 0
.fare_details
    // width: 300px
    margin-top: 20px
.table_padding
    padding: 10px

@import './../../../styles/variables'

.reg-container-height
    min-height: calc(100vh - 49px)
    .bg-color
        background: #00a19b
        .logo_uc
            justify-content: center
            align-items: center
            display: flex
            height: 100%

.createButton
    background-color: #1BA39C 
    border-color: #1BA39C 
    color: $cds-white 
    padding-left: 1.5rem 
    padding-right: 1.5rem 
    border-radius: 6px 
    display: block 
    width: 100% 
    text-align: center 
    padding: 14px 28px

.button
    background-color: #1BA39C 
    border: none

.heading
    margin-top: 18% 
    margin-left: 15% 

.placeholder
    font-size: 12px 
    background-color: white 
    color: black 
    width: 100% 
.form
    font-family: Arial, Helvetica, sans-serif
    font-size: 12px 
    align-items: center !important
    margin-top: 5% !important
    margin-bottom: 2% !important
    margin-left: 15% !important
    margin-right: 15% !important
   
.reg-multipleSelect
    position: relative !important
    display: inline-block !important
    background-color: white !important
    color: #535353 !important
    font-size: 12px !important 
    border-color: #1BA39C !important
    width: 100% !important
    height: 40px !important
    legend
        width: auto !important

.reg-multipleSelect:hover
    fieldset
        box-shadow: 0 0 0 0.25rem rgb(32 189 181 / 25%) !important
        border: none

.reg-multipleSelect:focus
    box-shadow: 0 0 0 0.25rem rgb(32 189 181 / 25%) !important
    border: none !important
    fieldset
        box-shadow: 0 0 0 0.25rem rgb(32 189 181 / 25%) !important
        border: none !important
        

.placeholder1
    padding-bottom: 2px !important
    padding-top: 2px !important
    border-color: #1BA39C !important
    color: black !important
    font-family: Arial, Helvetica, sans-serif !important
    
.multiSelectContent
    background-color: white !important
    color: #535353 !important
    font-size: 12px !important
    border-color: #1BA39C !important
.label 
    color: black 
    font-size: 12px !important
    width: 100% 
    padding-bottom: 10px !important
.checkbox
    padding-left: 25px !important
//Success components styling
.no-border
    border:none 
.footer 
    background-color: white
    border : 0 
.body
    font-family: Arial, Helvetica, sans-serif 
    padding-left: 16px 
    padding-top: 10px 
.header
    padding:16px 16px 
    padding-top: 16px 
    font-family: Arial, Helvetica, sans-serif 
.footerButton
    color: #1BA39C  
    background-color: white
    border: 2px solid #1BA39C  
    border-radius: 15px 
    padding: 10px 25px 
    width: 30% 
    text-align: center 
    .footerButton:hover
        background-color: #1BA39C  
        color: white 
::placeholder
    font-size: 14px 
    color: black !important
    font-family: Arial, Helvetica, sans-serif
.form-check-label
    padding-top: 1px

.addMore
    color: $cds-text
    display: flex
    flex-direction: row-reverse
    &:hover
      color: $cds-navbarIconHighlight
      cursor: pointer
      .svg__icon
        fill: $cds-navbarIconHighlight


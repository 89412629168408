.topnav
    text-align: center
.topnav input[type=text]
    width: 600px
    padding: 6px
    border: none !important
    margin-top: 200px
    margin-right: 16px
    font-size: 17px
table
    font-family: arial, sans-serif
    border-collapse: collapse
    width: 100%
td, th
    border: 1px solid
    text-align: left

.margin_outStanding
    margin: 10px 30px 10px 30px
.table_outStanding

    border: 1px solid

.tableHead_outStanding
    background-color: #dddddd
    border: none !important
.tableHorizontal_outStanding
    border: 1px solid
.total_outStanding
    float: right
    width: 40%
.h6_outStanding
    width: 200px !important
.border-bottom_outStanding
    border: 2px black
.saveButton_outStanding
    float: right
    margin-right: 20px
.invoiceNo_outStanding
    margin-left: 40px
.invoiceDate_outStanding
    margin-left: 10px !important
.total-table_outStanding
    background-color: #dddddd
    border: 1px solid
    margin-left: 50px
    width: 100%
    margin-right: 255px
.totalValues_outStanding
    padding-left: 90px
.total_style_outStanding
    padding-left: 400px
.table_invoice_outStanding
    width: 40%
    border-radius: 15px 50px

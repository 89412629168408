@import './../../../styles/variables'

.breadcrumb__rfq
    list-style: none
    padding: 0.5rem 0
    margin: 0 1rem
    li
        display: inline
        font-size: 14px

    li+li:before
        padding: 2px
        color: $cds-black
        content: "/"
    a
        color: $cds-breadcrumbBlack
        text-decoration: none

    a:hover, .active
        color: $cds-primary
        text-decoration: none
    a.notActive
        color: $cds-breadcrumbBlack !important
        text-decoration: none
    a.notActive:hover
        color: $cds-primary !important

.partitionLine
    height: 0.5px
    margin: 0 1rem
    border: 1px solid $cds-separatorLine

.modal__supplierOrder
    min-width: 90%
    .modal__supplierOrderBody
        padding: 1rem !important
        .modal__supplierOrderTitleRow
            font-weight: bold
            background-color: #ccc
            display: flex
            align-items: center
            justify-content: space-between
        .bodyRow
            background-color: #eee
            .order__details
                display: grid
                grid-template-columns: 1.5fr 1fr 1fr
                padding: 1rem 1rem 0 1rem
                h6:first-child
                    font-weight: bold
                h6:last-child
                    margin: 0
            .partitionLine
                height: 1px
                background-color: #ccc
                margin: 1.5rem 0 !important
            .productItemDetail
                margin: 1rem 2rem
                border: 1px solid #000
                .titleRow
                    background-color: #ccc
                    display: flex
                    align-items: center
                    border-bottom: 1px solid #000
                .contentRow
                    margin: 20px 30px
                    .grid__container__orderDetails
                        display: grid
                        grid-template-columns: repeat(5, 1fr)
                        gap: 10px
                        grid-auto-rows: 50px
                        .grid__item>p
                            font-weight: bold
                    .delivery__address
                        padding: 1.5rem 0
                        border-top: 1px solid #ccc
                        border-bottom: 1px solid #ccc
                        margin: 2rem 0
                        h6:first-child
                            font-weight: bold
                    .grid__container__productDetails
                        display: grid
                        grid-template-columns: repeat(4, 1fr)
                        gap: 10px
                        grid-auto-rows: 50px
                        .grid__item>p
                            font-weight: bold
                    .artWork__container
                        margin: 2rem 0 1rem 0
                        display: grid
                        grid-template-columns: repeat(8, 1fr)
                        gap: 10px
                        .grid__item>p
                            font-weight: bold

@import './../../../styles/variables'

.header
  border-top: 1px solid #D5D5D5
  border-bottom: 1px solid #D5D5D5
  background-color: #FFF
  display: flex
  justify-content: space-between
  align-items: center
  // padding: 0.5rem 1rem
  position: sticky
  top: 0
  height: 3rem
  z-index: 20

  .header__secondContainer
    width: 100%
    height: 100%
    display: flex
    align-items: center
    flex-wrap: nowrap

    .search-icon
      margin-left: 1rem
    .search-input
      width: 100%
      height: 100%
      background-color: $cds-white
      color: #545454
      border: none
      box-shadow: none
    .notification
      margin-left: 2rem
      .bell__icon__container
        border: 0.1rrem solid rgb(243, 242, 241)
        background-color: rgb(214, 214, 213)
        border-radius: 2rem
        display: flex
        justify-content: center
        align-items: center
        width: 30px
        height: 30px
        transition: all 0.4s ease-out
        &:hover
          transform: scale(1.2)
          transition: all 0.4s ease-out
          cursor: pointer

        .bell__icon
          color: rgb(162, 163, 163)
      .notes
        cursor: pointer
        transition: all 0.4s ease-out
        &:hover
          transform: scale(1.5)
          transition: all 0.4s ease-out

.notes__panel
  position: fixed
  width: 200px
  right: -200px
  top: 3.5rem
  background-color: $cds-black
  height: 100%
  padding: 1rem
  color: #fff
  transition: right 0.8s ease-out
  display: flex
  justify-content: space-between
  align-content: center
.notes__panel.enter
  right: 0

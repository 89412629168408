// Override default variables before the import
@import './../styles/variables'

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap"
*,
*::before,
*::after
  box-sizing: border-box

html,
body
  height: 100%
  width: 100%
  font-family: Arial
  font-size: 16px
  background-color: $cds-background
  &::-webkit-scrollbar
    width: 5px
    height: 5px
    cursor: pointer
  &::-webkit-scrollbar-thumb
    background: #888
    border-radius: 10px
  &::-webkit-scrollbar-track
    background: #f1f1f1
p
  margin: 0
#root
  min-height: 100%
  min-width: 100%

p,
label
  //font-family: Georgia, Times, 'Times New Roman', serif
  line-height: 1.5em

input, select
  font-family: inherit
  font-size: inherit

.page-row
  display: flex
  .menu-side-bar
    padding-left: 0
    margin-top: 0
    padding-right: 0
    width: 276px
    position: fixed
    z-index: 100
    height: 100%
    min-height: 200px
    overflow-y: auto
    overflow-x: hidden
    background-color: $cds-black
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none

  .right-container
    background-color: $cds-background
    padding-left: 0
    padding-right: 0
    width: 100%
    margin-left: 276px
    min-width: 1000px

.App
  text-align: center

.data-area-div
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-evenly

.todo-form
  width: 60%

.todo-list
  width: 60%

.todo-label
  font-size: large
  margin-right: 22%
  margin-left: 3%

.todo-view-btn
  float: right

.center
  text-align: center
  display: flex
  justify-content: center
  align-items: center
.input__invalid
  border-color: #dc3545 !important
  padding-right: calc(1.5em + .75rem)
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E")
  background-repeat: no-repeat
  background-position: right calc(.375em + .1875rem) center
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)

//Form field styles
::placeholder
  color: $cds-placeHolderText !important
  opacity: 1

::-ms-input-placeholder
  color: $cds-placeHolderText !important

.text-danger
  color: $cds-required !important
  font-size: 1rem !important
  margin-top: 2px !important
.border-danger
  border: 1px solid $cds-required

.border-danger:focus, .border-danger:active, .border-danger:hover
  box-shadow: 0 0 0 0.25rem rgb($cds-required,0.25) !important

.border-danger-check
  input[type="checkbox"], input[type="radio"]
    border-color: $cds-required
.input__required
  color: $cds-asterisk !important
.form-label
  color: $cds-text
  span
    color: $cds-text
.form-control, .form-select, .form-check-input
  color: $cds-text
  font-size: 1rem

.form-control:focus, .form-select:focus, .form-check-input:focus
  border-color: $cds-primary
  box-shadow: 0 0 0 0.25rem rgb($cds-primary,0.25)

.form-control:hover, .form-select:hover, .form-check-input:hover
  border-color: $cds-checked
  box-shadow: 0 0 0 0.25rem rgb($cds-checked,0.25)
.form-control:disabled, .form-select:disabled, .form-check-input:disabled
  background-color: $cds-disabled !important
  pointer-events: none !important
.form-check-input:checked
  background-color: $cds-checked !important
  border-color: $cds-checked !important
.form-check-disabled
  pointer-events: none
  label
    opacity: 1 !important
  .form-check-input
    background-color: $cds-disabled !important
    border-color: $cds-formInputBorder !important
  .form-check-input:checked
    background-color: $cds-text !important
    border-color: $cds-formInputBorder !important
    opacity: 0.3 !important
.form-label
  pointer-events: none !important

.picker__container
  display: flex !important
  align-items: center
.picker__container__disabled
  background-color: $cds-disabled !important
  opacity: 1 !important
  pointer-events: none !important
.picker__input
  padding-top: 0 !important
  padding-bottom: 0 !important
  border: none !important
  outline: none !important
  color: $cds-text !important

//Button styles
.outline__button
  border-color: $cds-primary !important
  color: $cds-primary !important
  padding-left: 1.5rem !important
  padding-right: 1.5rem !important
  border-radius: 6px !important
  &:hover, &:focus, &:active
    background-color: $cds-primary !important
    border-color: $cds-primary !important
    color: $cds-white !important
    box-shadow: 0 0 0 0.25rem rgb($cds-primary,0.5) !important

.full__button
  background-color: $cds-primary !important
  border-color: $cds-primary !important
  color: $cds-white !important
  padding-left: 1.5rem !important
  padding-right: 1.5rem !important
  border-radius: 6px !important
  &:hover, &:focus, &:active
    background-color: $cds-checked !important
    border-color: $cds-checked !important
    box-shadow: 0 0 0 0.25rem rgb($cds-primary,0.5) !important
  &:disabled
    background-color: $cds-primaryDisabledButton !important
    border-color: $cds-primaryDisabledButton !important
    box-shadow: none !important
    opacity: 1 !important

//Modal styles
.fullScreen
  height: 100% !important
  width: 100% !important
  background: $cds-modalBackground !important
  opacity: 0.85 !important

.fullScreen__overPopup
  height: 100% !important
  width: 100% !important
  background: $cds-modalBackground !important
  opacity: 0.85 !important
  z-index: 1055

//Icon
.svg__icon
  fill: $cds-navbarText
  &:hover
    fill: $cds-navbarIconHighlight
    cursor: pointer
.svg__icon-large
  width: 5rem
  height: 5rem

//Tooltip
.icon-tooltip > div.tooltip-inner
  background-color: $black !important
  color: $white !important

.Mui-selected
  background-color: $cds-disabled !important
.Mui-hover
  background-color: $cds-disabled !important

.picker__input
  width: 108% !important
  background: transparent !important

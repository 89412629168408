@import './../../../styles/variables'

.myJobs__container



.item__details__table
  thead
    background-color: $cds-disabled
    border: 1px solid $cds-disabled
    .table__details__headerCell
      font-size: 1rem
      font-family: Arial
      padding: 0
      font-weight: bold
      color: $cds-text
  .table__details__row
    border: 1px solid $cds-disabled
    .table__details__bodyCell
      font-size: 14px
      font-family: Arial
      padding: 0
      border: none
      font-weight: bold
      color: $cds-productDetailsRow
  .open
    border-bottom: none
    
.addAccount__button
    color: $cds-text
    display: flex
    flex-direction: row-reverse
    &:hover
      color: $cds-navbarIconHighlight
      cursor: pointer
      .svg__icon
        fill: $cds-navbarIconHighlight

.table__edit:hover 
  font-weight: 555
@import './../../../../styles/variables'
.quotes__page
    .quotes__header
        display: flex
        justify-content: space-between
        align-items: center
        h6
            font-size: 18px
            margin-bottom: 0
        .edit__button
            color: $cds-text
            display: flex
            flex-direction: row-reverse
            gap: 4px
            align-items: center
            font-size: 1rem
            padding-right: 0.5rem
            &:hover
                color: $cds-navbarIconHighlight
                cursor: pointer
                .svg__icon
                    fill: $cds-navbarIconHighlight
    .quotes__details__headerCell
        font-size: 14px
        font-family: Arial
        padding: 0
        font-weight: bold
        color: $cds-text
    .quotes__details__bodyCell
        font-size: 14px
        font-family: Arial
        padding: 0
        border: none
        font-weight: normal
        color: $cds-productDetailsRow
        .notes__icon
            fill: $cds-black
            padding-bottom: 1px
            width: 1.3rem
            height: 1.3rem
            opacity: 1
            cursor: pointer
            &:hover
                fill: $cds-navbarIconHighlight
    .first__column
        display: flex
        align-items: center
        padding-left: 0 !important
        .quote__highlight
            height: 2.2rem
            width: 4px
            background-color: $cds-navbarIconHighlight

    .quotes__details__subContainer
        padding: 0
        border: 1px solid $cds-disabled
        font-family: Arial
        border-top: none
        .quotes__details__separator
            height: 0.05rem
            widhth: 100%
            background-color: $cds-separatorLine
            margin: 0rem 1rem 1rem 1rem

.assign__suppliersButtonContainer
    margin: 150px 0
    .assignSuppliers__button
        display: flex
        flex-direction: column
        align-items: center
        span
            font-size: 1rem
            color: $cds-text
.modal__assignSuppliers
    min-width: 65%
    .modal-content
        height: 680px !important
        .modal-body
            padding: 0 1rem !important
        .modal-footer
            border-top: none
           

.suppliers__search__header
    display: flex
    justify-content: center
    margin: 0.5rem auto !important
    background: url('../../../../images/search.svg') no-repeat
    background-position: 98% 50%
    background-size: 1.5rem 1.5rem
    padding-right: 3rem !important
.time__pickerContainer
    padding-top: 2.5px !important
    padding-bottom: 2.5px !important
.time__pickerContainer__disabled > span > input
    background-color: $cds-disabled
.timePicker__input
    border: none !important
    font-size: 1rem !important
    color: $cds-text !important
.rc-time-picker
    width: 100% !important
.supplier__modalBody
    display: flex
    flex-direction: column
    height: 90%
    .top__half
        // height: 60%
        padding-top: 0.5rem
        border-bottom: 1px solid #a3a3a3
    .bottom__half
        height: 20%
        padding: 4px
        // overflow-y: scroll
        // &::-webkit-scrollbar
        //     display: none
.MuiDataGrid-footerContainer
    display: none !important
.supplierForm
    display: grid
    margin-bottom: 1rem
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: 15px
    .text-danger
        font-size: 1rem !important
        font-weight: lighter !important
        margin-top: 2px !important
.form__fieldCheckk
    padding: 0 !important

.quoteBrokerageOverride
    grid-column: 3/5
    width: 52%
    .quoteBrokerageOverride__percentage
        position: relative
        top: -25px
        left: 175px
        border: 1px solid #a3a3a3
        width: max-content
        padding: 5px
.react-datepicker__triangle
    &::after, &::before
        left: -13px !important

.supplierData__form
    display: flex
    flex-direction: column
    width: 65%
    div
        display: flex
        flex-direction: column
        margin-bottom: 1.5rem
        span
            color: $cds-text
            font-weight: normal
            font-size: 1rem
        .cdsNotes
            color: $cds-productDetailsRow
            font-weight: normal
        div
            margin: 0.5rem 0 0 0
            .supplier__note__textArea
                font-size: 14px
                color: $cds-productDetailsRow
            .material__information__span
                color: $cds-text
                font-weight: normal
                margin-left: 0.8rem
                font-size: 14px

            table
                width: 100%
                thead
                    background-color: $cds-disabled
                    border: 1px solid $cds-disabled
                    tr > th
                        padding: 0.2rem
                        color: $cds-productDetailsRow
                        font-weight: bold
                        font-size: 14px
                    tr > :first-child
                        padding-left: 0.8rem
                tbody
                    tr > td
                        border-bottom: 1px solid $cds-disabled
                        border-top: 1px solid $cds-disabled
                        color: $cds-productDetailsRow
                        font-weight: normal
                        padding: 0.2rem
                    tr > :first-child
                        border-left: 1px solid $cds-disabled
                        padding-left: 0.8rem
                    tr > :last-child
                        border-right: 1px solid $cds-disabled
.modal__maxFullScreen
    min-width: 85%
.modal__body::-webkit-scrollbar
    display: none

.clientQuoteConfirmation
    padding: 40px
    .font-14
        font-size: 14px !important
    .top
        .reference_jobId
            display: block
            background-color: #ECECEC
            padding: 15px
        .margin__container
            margin: 0 15px
            .container
                border-bottom: 1px solid #ECECEC
                padding: 10px 0
                & h6
                    font-weight: bold
                & > table
                    width: 80%
                    & > tbody > tr > td
                        width: 120px
    .productItemDetails
        margin-top: 15px
        .productItemDetail
            border: 1px solid #ECECEC
            margin-bottom: 15px
            .titleRow
                border-bottom: 1px solid #ECECEC
                background-color: #ECECEC
                padding: 15px
            .contentRow
                margin: 20px 30px
                .grid__container
                    display: grid
                    grid-template-columns: repeat(5, 1fr)
                    gap: 10px
                    grid-auto-rows: 50px
                .delivery__instructions
                    margin-top: 30px
                    h6
                        font-weight: bold
                .priceSummary
                    margin-top: 30px
                    h6
                        font-weight: bold
                    .priceSummary__container
                        display: grid
                        grid-template-columns: repeat(6,1fr)
                        gap: 10px
                        margin-bottom: 20px

    .summary
        display: flex
        justify-content: end
        table
            background: #ececec
            td
                padding: 10px
                margin: 30px 15px 30px 0
                justify-content: flex-end
        table
            width: 60%
        h6
            font-weight: bold
.edit_supplier_quote
    margin-left: 120%
    width: 190px
.header-assign-supplier
    margin-left:10%
    margin-top:5%

.rc-time-picker-panel-combobox
    display: flex

.custom-select-input__placeholder
    font-size: 14px !important
    display: flex    

.assign-supplier-select-input__placeholder
    font-size: 14px !important
    color: #c4c4c4 !important

.assign-supplier-select-input__indicator-separator
    display: none

.assign-supplier-select-input__option--is-selected
    span:last-child
        color: rgba($cds-white, 0.6)
    span:first-child
        color: $cds-white
.assign-supplier-select-input__control
    border: 1px solid $cds-formInputBorder !important
    &:hover
        border: 1px solid $cds-primary !important
        box-shadow: 0 0 0 0.25rem rgb($cds-checked,0.25) !important
.assign-supplier-select-input__control--is-focused
    border: 1px solid $cds-primary !important
    box-shadow: 0 0 0 0.25rem rgb($cds-checked,0.25) !important
.assign-supplier-select-input__control--menu-is-open
    border: 1px solid $cds-primary !important
    box-shadow: 0 0 0 0.25rem rgb($cds-checked,0.25)
.assign-supplier-select-input__option--is-focused
    background: rgba($cds-navbarIconHighlight, 0.25) !important
.assign-supplier-select-input__single-value
    font-size: 1rem !important
    color: $cds-text !important
.assign-supplier-select-input__option--is-focused
    background: rgba($cds-navbarIconHighlight, 0.25) !important
.assign-supplier-select-input__option--is-selected
    background-color: rgba($cds-navbarIconHighlight, 0.7) !important


.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: auto;
    border-radius: 50%;
    border: 5px solid #000000;
    border-color: var(--spinner-color) transparent var(--spinner-color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  
  .loading-spinner__overlay {
    /* height: 100%; */
    width: 100%;
    /* margin: auto; */
    display: grid;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
@import '../../../../styles/variables'

$black: #363839
$lightgray: #9c9e9f
$gray: #bdc1c6
$white: #fff
$fill: #000000

.rfq__container
  margin: 0.8rem 1rem
  box-shadow: 0px 3px 6px 3px $cds-dropShadow
  background-color: $cds-white
  border: 1px solid $cds-border
  border-radius: 5px
  opacity: 1
  min-height: 100vh
  .rfq__header
    margin: 1rem
    h2
      font-size: 25px
      color: $cds-headerText
    h3
      font-size: 20px
      color: $cds-navbarText
.inPage__navigation
  margin-left: 1rem
  display: flex
  justify-content: flex-start
  button
    margin-right: 3rem
    border: none
    padding: 0
    color: $cds-text
    background: none
    font-weight: bold
    margin-bottom: -1px
    box-sizing: content-box
    border-bottom: solid 4px transparent
    padding-bottom: 0.3rem

  button.active, button:hover
    border-bottom: solid 4px $cds-primary
    color: $cds-primary

.inPage__navigation__line
  height: 0.05rem
  widhth: 100%
  background-color: $cds-separatorLine
  margin: 0 1rem
.job__information,.form__contact, .form__cds,.form__invoice,.form__forecastDate
  .form__row
    display: grid
    margin-bottom: 1rem
    grid-template-columns: 1fr 1fr 1fr 1fr
    grid-gap: 15px
    margin-bottom: 1rem

.form__container
  margin: 15px 15px

.form__subHeader
  margin-bottom: 1rem
  margin-top: 3rem
  font-weight: bold
  font-size: 18px
.top__subHeader
  margin-top: 1rem
.form-label
  color: #5e5e5e

.extra__container,center_button
  position: absolute
  top: 50%
  left: 50%
.addProduct__container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  & > span
    color: $cds-text

.productItem__classContainer
  list-style: none
  padding: 0

.productItem__class
  padding: 0.5rem 1.5rem
  .form-check
    padding-left: 0
  .form-check-input[type=radio]
    float: right
  &:hover
    background: rgba($cds-navbarIconHighlight, 0.25) !important
    cursor: pointer
    .form-check-input
      border-color: $cds-primary
      box-shadow: 0 0 0 0.25rem rgb($cds-primary,0.25)

.modal__searchProductItems
  width: 34% !important
  min-width: 400px !important
  .modal-content
    min-height: 450px !important
    .modal-header
      padding-left: 1.5rem !important
      padding-right: 1.5rem !important
    .modal-footer
      border-top: none !important
      padding-right: 2rem !important

.search-products
  width: 90% !important
  display: flex
  justify-content: center
  margin: 1rem auto !important
  background: url('../../../../images/search.svg') no-repeat
  background-position: 95% 50%
  background-size: 1.5rem 1.5rem
  padding-right: 3rem !important

footer
  position: fixed
  bottom: 0
  right: 0
  width: 100%
  background-color: $cds-white
  box-shadow: 0px -3px 6px $cds-dropShadow
  border: 1px solid $cds-border
  color: white
  display: flex
  justify-content: flex-end
  height: 3rem
  padding: 0.3rem 2rem 0.3rem 276px
  gap: 20px

.products__content
  margin-bottom: 3.5rem
  margin-left: 1rem
  margin-right: 1rem

.products__inpageNavigation
  margin-left: 1rem
  display: flex
  justify-content: flex-start
  div
    margin-right: 3rem
    button
      margin-right: 8px
      border: none
      padding: 0
      background: none

  button.active, button:hover
    border-bottom: solid 2px #000

.products__inpageNavigationLine
  height: 0.05rem
  widhth: 100%
  background-color: #a0a0a0
  margin: 0 1rem 0 1rem

.contactName__option, .contactName__single-value
  white-space: pre-wrap !important

.contactName__control
  &:focus
    border-color: $cds-primary !important
    box-shadow: 0 0 0 0.25rem rgb($cds-primary,0.25)
    cursor: text
  &:hover
    border-color: $cds-checked !important
    box-shadow: 0 0 0 0.25rem rgb($cds-checked,0.25)
    cursor: text
.contactName__control--is-focused
  border-color: $cds-primary !important
  box-shadow: 0 0 0 0.25rem rgb($cds-primary,0.25) !important
  cursor: text
// .contactName__menu
  // height: 6rem
.contactName__menu-list
  // height: 6rem
  &>div:hover
    cursor: pointer !important
  &::-webkit-scrollbar
    width: 5px
    height: 10px
    cursor: pointer
  &::-webkit-scrollbar-thumb
    background: #888
    border-radius: 2px
  &::-webkit-scrollbar-track
    background: #f1f1f1
.contactName__indicator-separator
  display: none
.contactName__indicators:hover
  cursor: pointer
.contactName__indicator
  padding: 0 !important
.contactName__indicator:last-child
  padding-right: 0.7rem !important
.contactName__value-container
  padding-top: 0 !important
  padding-bottom: 0 !important
.contactName__single-value
  font-size: 1rem !important
  color: $cds-text !important
.contactName__option--is-focused
  background: rgba($cds-navbarIconHighlight, 0.25) !important
.contactName__option--is-selected
  background-color: rgba($cds-navbarIconHighlight, 0.7) !important

.search__select

  span:first-child
    display: block
    color: $cds-text
  span:last-child
    font-size: 14px
    color: $cds-mail
.contactName__option--is-selected
  span:first-child
    color: $cds-white
  span:last-child
    color: rgba($cds-white, 0.6)
.phone-input:focus
  border: 1px solid $cds-primary !important
  box-shadow: 0 0 0 0.25rem rgb($cds-checked,0.25) !important

.modal__printSpecificationForm
  // width: 85% !important
  min-width: max(85%, 800px) !important
  .modal-content
    min-height: 450px !important
    .modal-header
      padding-left: 1.5rem !important
      padding-right: 1.5rem !important
      border-bottom: none !important
      h2
        color: $cds-headerText
        font-size: 25px
      h3
        color: $cds-navbarText
        font-size: 20px
        margin-bottom: 0
    .modal-body
      padding: 1rem 3rem !important
    .modal-footer
      border-top: none !important
      padding-right: 2rem !important

//Buttons Header
.productsPage__header
  margin: 1rem
  display: flex
  justify-content: space-between
  align-items: center
  h4
    font-size: 18px
    margin: 0
    color: $cds-text
    font-weight: bold
  label
    margin-bottom: 0 !important
    color: $cds-text
  .form-check
    margin-bottom: 0 !important
    margin-right: 0.5rem !important
.buttons__container
  display: flex
  & > p
    padding-right: 0.6rem
    width: max-content

.header__button
  color: $cds-text
  display: flex
  flex-direction: row-reverse
  gap: 4px
  align-items: center
  transition: 0.2s all ease-out
  &:hover
    color: $cds-navbarIconHighlight
    cursor: pointer
    transform: scale(1.05)
    transition: 0.3s all ease-out
    .svg__icon
      fill: $cds-navbarIconHighlight
.header__button-active
  .svg__icon
    fill: $cds-navbarIconHighlight
.header__button-disabled
  color: $cds-placeHolderText
  pointer-events: none
  .svg__icon
    fill: $cds-placeHolderText
  &:hover
    color: $cds-placeHolderText
    .svg__icon
      fill: $cds-placeHolderText

//Product Item Details
.productItem__details__table
  thead
    background-color: $cds-disabled
    border: 1px solid $cds-disabled
    .productItem__details__headerCell
      font-size: 1rem
      font-family: Arial
      padding: 0
      font-weight: bold
      color: $cds-text
  .productItem__details__row
    border: 1px solid $cds-disabled
    .productItem__details__bodyCell
      font-size: 14px
      font-family: Arial
      padding: 0
      border: none
      font-weight: bold
      color: $cds-productDetailsRow
      padding-right: 0rem
      word-break: break-all
  .open
    border-bottom: none
  .productItem__details__subContainer
    padding: 0
    border: 1px solid $cds-disabled
    font-family: Arial
    border-top: none
    .productDetails__separator
      height: 0.05rem
      widhth: 100%
      background-color: $cds-separatorLine
      margin: 0rem 1rem 1rem 1rem

.center_button
  position: absolute
  top: 50%
  left: 50%
.center_container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  & > span
    color: $cds-text
.modal_footer
  border-top: 0 none
.modal_form
  font-size: 16px
  font-family: Arial, Helvetica, sans-serif
  padding-left: 20px !important
  padding-right: 20px !important
  padding-top: 3px !important
  padding-bottom: 3px !important
  .modal_placeholder
    width: 455px !important
    .modal_halfPlaceholder
      width: 215px !important
.left_header
  padding-left: 10px !important
  padding-top: 10px !important
.right_header
  padding-right: 15px !important
  padding-top: 10px !important
.tabular
  padding-left: 20px !important
  padding-right: 20px !important
  padding-top: 3px !important
  padding-bottom: 3px !important
.othercosts_row
  font-size: 1rem
  font-family: Arial
  padding: 0
  font-weight: bold
  color: $cds-text

.disabledBtn
  color: #ececec !important

.modal__popup_pod
  min-width: 450px !important
  width: 450px !important
  .modal-content
    min-height: 400px !important
    height: 500px !important
    .modal-header
      padding-left: 1rem !important
      padding-right: 1rem !important
      .modal-footer
        border-top: none !important
        padding-right: 1rem !important

.modal_popup_body
  padding-left: 1.25rem !important
  padding-right: 1.25rem !important
  height: 350px !important

.modal__popup_task
  font-size: 16px
  min-width: 550px !important
  width: 800px !important
  height: 530px !important

.modal_body_task
  padding-left: 5% !important
  padding-right: 5% !important
  height: 400px !important
  width: 550px !important

.submit-padding
  padding-left: 4rem

.use_supplier_quote_handler
  padding: 20px 20px 20px 20px
  .body__titleContainer
    label
      font-size: 16px
      font-weight: 700
  .body_container
    padding-top: 14px
    .info_container
      border: solid 1px #ccc
      padding: 20px
      border-radius: 10px
      .info_header
        padding-bottom: 10px
        display: flex
        label
          font-size: 16px
          font-weight: 700
        :last-child
          margin-left: auto
          font-weight: 300
      .info_body
        .info_body_runon_information
          padding-top: 10px
          .runon_title
            padding-bottom: 10px
        table
          tr
            border: solid 1px #ccc
            th, td
              padding: 0px 10px 0px 10px
              font-weight: 300
.outline__button_productConfirmation
  width: 220px
  border-color: $cds-primary !important
  color: $cds-primary !important
  padding-left: 1.5rem !important
  padding-right: 1.5rem !important
  border-radius: 6px !important
  &:hover, &:focus, &:active
    background-color: $cds-primary !important
    border-color: $cds-primary !important
    color: $cds-white !important
    box-shadow: 0 0 0 0.25rem rgb($cds-primary,0.5) !important

.job__information_container
  .text-danger
    font-size: 1rem !important
    font-weight: lighter !important
    margin-top: 2px !important

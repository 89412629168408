@import './../../../styles/variables'

.myJobs__container

.Invoice_Body_Recheck_button
  height:40px
  width:150px
  
.Invoice_Body_head
  color: #968d8d
 
.invoiceFooter
 width:83.3%
 bottom:0px

.border-left
  border-left: solid 1px #ccc
@import '../../../../styles/variables'
$black: #363839
$lightgray: #9c9e9f
$gray: #bdc1c6
$white: #fff
$fill: #000000

.item__details__table
  thead
    background-color: $cds-disabled
    border: 1px solid $cds-disabled
    .table__details__headerCell
      font-size: 1rem
      font-family: Arial
      padding: 0
      font-weight: bold
      color: $cds-text
  .table__details__row
    border: 1px solid $cds-disabled
    .table__details__bodyCell
      font-size: 14px
      font-family: Arial
      padding: 0
      border: none
      font-weight: bold
      color: $cds-productDetailsRow
  .open
    border-bottom: none
.page__header
  margin: 1rem
  display: flex
  justify-content: space-between
  align-items: center
  h4
    font-size: 18px
    margin: 0
    color: $cds-text
    font-weight: bold
  label
    margin-bottom: 0 !important
    color: $cds-text
  .form-check
    margin-bottom: 0 !important
    margin-right: 0.5rem !important
.buttons__container
  display: flex
  & > p
    padding-right: 0.6rem
    width: max-content
.header_buttons
  padding-left: 800px !important
.header_span_spacing
  padding-left: 20px !important
  padding-right: 20px !important
  padding-top: 100px !important
.fullScreenStyling
  padding: 20px !important
  padding-right: 20px !important
.modal__popup
  min-width: 450px !important
  width: 450px !important
  .modal-content
    min-height: 300px !important
    height: 300px !important
    .modal-header
      padding-left: 1rem !important
      padding-right: 1rem !important
      .modal-footer
        border-top: none !important
        padding-right: 1rem !important
    .popupModal__bodyContainer
      margin: 1.5rem 1rem 1rem 1rem !important
      display: flex
      flex-direction: column
      align-items: flex-start
      .body__titleContainer
        background-color: $cds-white
        position: sticky
        top: 0
        display: flex
        align-items: center
        .body__title
          padding-left: 1rem
        .body__contentContainer
          margin-left: 2.5rem
          margin-top: 0.5rem
.header__button
  color: $cds-text
  display: flex
  flex-direction: row-reverse
  gap: 4px
  align-items: center
  transition: 0.2s all ease-out

.outline__button_su
  width: 200px
  border-color: $cds-primary !important
  color: $cds-primary !important
  padding-left: 1.5rem !important
  padding-right: 1.5rem !important
  border-radius: 6px !important
  &:hover, &:focus, &:active
    background-color: $cds-primary !important
    border-color: $cds-primary !important
    color: $cds-white !important
    box-shadow: 0 0 0 0.25rem rgb($cds-primary,0.5) !important

.modal__popup_su
  min-width: 950px !important
  width: 950px !important

@import './../../../styles/variables'
$black: #363839
$lightgray: #9c9e9f
$gray: #bdc1c6
$white: #fff
$fill: #000000

.item__details__table
  thead
    background-color: $cds-disabled
    border: 1px solid $cds-disabled
    .table__details__headerCell
      font-size: 1rem
      font-family: Arial
      padding: 0
      font-weight: bold
      color: $cds-text
  .table__details__row
    border: 1px solid $cds-disabled
    .table__details__bodyCell
      font-size: 14px
      font-family: Arial
      padding: 0
      border: none
      font-weight: bold
      color: $cds-productDetailsRow
  .open
    border-bottom: none
.page__header
  margin: 1rem
  display: flex
  justify-content: space-between
  align-items: center
  h4
    font-size: 18px
    margin: 0
    color: $cds-text
    font-weight: bold
  label
    margin-bottom: 0 !important
    color: $cds-text
  .form-check
    margin-bottom: 0 !important
    margin-right: 0.5rem !important
.buttons__container
  display: flex
  & > p
    padding-right: 0.6rem
    width: max-content
.header_buttons
  float: right
.header_span_spacing
  padding-left: 1.25rem !important
  padding-right: 1.25rem !important
  padding-top: 6.25rem !important
.button_spacing
  margin: 0 5px

.fullScreenStyling
  padding-left: 1.25rem !important
  padding-right: 1.25rem !important
.modal__popup
  min-width: 281.125rem !important
  width: 281.125rem !important
  .modal-content
    min-height: 18.75rem !important
    height: 18.75rem !important
    .modal-header
      padding-left: 1rem !important
      padding-right: 1rem !important
      .modal-footer
        border-top: none !important
        padding-right: 1rem !important
    .popupModal__bodyContainer
      margin: 1.5rem 1rem 1rem 1rem !important
      display: flex
      flex-direction: column
      align-items: flex-start
      .body__titleContainer
        background-color: $cds-white
        position: sticky
        top: 0
        display: flex
        align-items: center
        .body__title
          padding-left: 1rem
        .body__contentContainer
          margin-left: 2.5rem
          margin-top: 0.5rem
.header__button
  color: $cds-text
  display: flex
  flex-direction: row-reverse
  gap: 4px
  align-items: center
  transition: 0.2s all ease-out
.createQuote_button
  // padding-left: 43.75rem !important
  float: right

.form_styling
  margin: 5px 0
.square_box
    height: 5.5rem
.width_quotation
  width: 40%
.table_information
  width: 40.6rem !important

.table_information_data
  text-align: right !important
.notes_supplier
  width: 60% !important
  height: 3.125rem!important
.date_time
  width: 31.25rem !important
.quote_instructions
  height: 6.25rem !important
  width: 25rem !important
.edit
  float: right !important
  background: none !important
.title_supp
  font-weight: bold

@import './../../../styles/variables'
.custom-select-group
    display: flex
    flex-direction: column
    .text-danger
        font-size: 1rem !important
        margin-top: 2px !important
.custom-select
    padding: 0 !important
    border: 1px solid transparent !important
.custom-select-input
    border: 0 !important

// .custom-select-input__option, .custom-select-input__single-value
//     white-space: pre-wrap !important

.custom-select-input__control
    border: 1px solid $cds-formInputBorder !important
    &:hover
        border: 1px solid $cds-primary !important
        box-shadow: 0 0 0 0.25rem rgb($cds-checked,0.25) !important
.custom-select-input__control--is-focused
    border: 1px solid $cds-primary !important
    box-shadow: 0 0 0 0.25rem rgb($cds-checked,0.25) !important
.custom-select-input__control--menu-is-open
    border: 1px solid $cds-primary !important
    box-shadow: 0 0 0 0.25rem rgb($cds-checked,0.25)

.custom-select-input__menu-list
    &>div:hover
        cursor: pointer !important
    &::-webkit-scrollbar
        width: 5px
        height: 10px
        cursor: pointer
    &::-webkit-scrollbar-thumb
        background: #888
        border-radius: 2px
    &::-webkit-scrollbar-track
        background: #f1f1f1
.custom-select-input__indicator-separator
    display: none
.custom-select-input__indicators:hover
    cursor: pointer
.custom-select-input__indicator
    padding: 0 !important
.custom-select-input__indicator:last-child
    padding-right: 0.7rem !important
.custom-select-input__value-container
    padding-top: 0 !important
    padding-bottom: 0 !important
.custom-select-input__single-value
    font-size: 1rem !important
    color: $cds-text !important
.custom-select-input__option--is-focused
    background: rgba($cds-navbarIconHighlight, 0.25) !important
.custom-select-input__option--is-selected
    background-color: rgba($cds-navbarIconHighlight, 0.7) !important
.custom-select-input__control--is-disabled
    background-color: $cds-disabled !important
    &:hover
        cursor: not-allowed !important
// .custom-select-input--is-disabled
//     pointer-events: all !important
.custom-select-disabled
    pointer-events: none !important
.custom-select-input__placeholder
    color: $cds-placeHolderText !important
.border-danger-select
    .custom-select-input__control
        border: 1px solid $cds-required !important
        &:hover
            border: 1px solid $cds-required !important
            box-shadow: 0 0 0 0.25rem rgb($cds-required,0.25) !important
    .custom-select-input__control--is-focused
        border: 1px solid $cds-required !important
        box-shadow: 0 0 0 0.25rem rgb($cds-required,0.25) !important
    .custom-select-input__control--menu-is-open
        border: 1px solid $cds-required !important
        box-shadow: 0 0 0 0.25rem rgb($cds-required,0.25) !important

.search__select
    span:last-child
        font-size: 14px
        color: $cds-mail
    span:first-child
        display: block
        font-size: 16px
        color: $cds-text
.custom-select-input__option--is-selected
    span:last-child
        color: rgba($cds-white, 0.6)
    span:first-child
        color: $cds-white
.input__required
    widht: 570px!important
    height: 32px!important

.other__filed_form_label
    display: flex
    .count_textArea
        margin-left: auto !important
    .text-danger
        font-size: 1rem !important

@import './../../../styles/variables'

.myProfile
    .myProfile__container
        position: relative
        margin: 0.8rem 1rem
        box-shadow: 0px 3px 6px 3px $cds-dropShadow
        background-color: $cds-white
        border: 1px solid $cds-border
        border-radius: 5px
        opacity: 1
        height: calc(100vh - 7.5rem)
        .inpage__navigation__line
            height: 0.05rem
            widhth: 100%
            background-color: $cds-separatorLine
            margin: 0 1rem
        .inpage__navigation
            margin: 1rem 1rem 0rem 1rem
            display: flex
            justify-content: flex-start
            button
                margin-right: 3rem
                border: none
                padding: 0
                color: $cds-text
                background: none
                font-weight: bold
                margin-bottom: -1px
                box-sizing: content-box
                border-bottom: solid 4px transparent
                padding-bottom: 0.3rem

            button.active, button:hover
                border-bottom: solid 4px $cds-primary
                color: $cds-primary
        .account__container
            padding: 20px
            .account__list
                border: solid 1px
                border-color: #dee2e6
                div
                    border-bottom: solid 1px
                    border-color: #dee2e6
                .account__list__header
                    background: #dee2e6
                    font-weight: 700
                .account__list__name
                    padding-left: 20px
                .account__body
                    height: calc(100vh - 19rem)
                    overflow-y: auto
        .team__container
            padding: 20px
            .add__user
                display: flex
                justify-content: end
                cursor: pointer
            table
                width: 100%
                margin-bottom: 1rem
                color: #212529
                vertical-align: top
                border: solid 1px
                border-color: #dee2e6
                tbody
                    display: block
                    height: calc(100vh - 21rem)
                    overflow-y: auto
                thead, tbody
                    tr
                        display: table
                        table-layout: fixed
                        width: 100%
                        th
                            background: #dee2e6
                        th, td
                            border-bottom: solid 1px
                            border-color: #dee2e6
                            padding: 10px 10px 10px 20px
                        :last-child
                            display: flex
                            justify-content: end
                            padding-right: 20px
                    .delete__action
                        padding-right: 12px !important
                        cursor: pointer
        .pagination
            display: flex
            justify-content: center
            position: absolute
            bottom: 0
            width: 99%
            padding: 10px

// _library.scss

$cds-primary: #1BA39C
$cds-black: #1B1B1B
$cds-white: #FFFFFF
$cds-navbarText: #8C8B8C
$cds-navbarIconHighlight: #01A09B
$cds-checked: #20BDB5
$cds-disabled: #ECECEC
$cds-required: #D62A44
$cds-separatorLine: #CCCCCC
$cds-placeHolderText: #C4C4C4
$cds-text: #535353
$cds-formInputBorder: #CACCCC
$cds-background: #F2F5F5
$cds-breadcrumbBlack: #58595B
$cds-headerText: #19191B
$cds-border: #E6E8E8
$cds-dropShadow: #0000000D
$cds-asterisk: #D8374F
$cds-mail: #8D8C8D
$cds-primaryDisabledButton: #98D9D7
$cds-modalBackground: #000000
$cds-productDetailsRow: #767576
$cds-red: red

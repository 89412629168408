@import './../../../styles/variables'

.dashboard__container
  padding:20px
  background:#fff
  .ArrowDropDownIcon
    height: 60px
    width: 60px
    color: red
    fill: currentColor
  .cds-text-primary
    color: $cds-primary
  .text-danger
    font-size: 2rem !important
  .dashboard__left__continer
    width: 20%
    .spend__container 
      .card__div:nth-child(odd)
        margin-top: 20px !important
      .card__div:nth-child(even)
        margin-top: 10px !important
  .dashboard__right__continer
    width: 80%
    .kpis__left
      width: 20%
    .kpis__right
      width: 80%
